import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

// const mock = [
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img23.jpg",
//     description:
//       "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
//     title: "Eiusmod tempor incididunt",
//     author: {
//       name: "Clara Bertoletti",
//       avatar: "https://assets.maccarianagency.com/avatars/img1.jpg",
//     },
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img24.jpg",
//     description: "At vero eos et accusamus et iusto odio dignissimos ducimus",
//     title: "Sed ut perspiciatis",
//     author: {
//       name: "Jhon Anderson",
//       avatar: "https://assets.maccarianagency.com/avatars/img2.jpg",
//     },
//     date: "02 Aug",
//   },
//   {
//     image: "https://assets.maccarianagency.com/backgrounds/img25.jpg",
//     description:
//       "Qui blanditiis praesentium voluptatum deleniti atque corrupti",
//     title: "Unde omnis iste natus",
//     author: {
//       name: "Chary Smith",
//       avatar: "https://assets.maccarianagency.com/avatars/img3.jpg",
//     },
//     date: "05 Mar",
//   },
// ];

const SimilarStories = ({ similar }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        marginBottom={4}
      >
        <Box>
          <Typography fontWeight={700} variant={"h6"} gutterBottom>
            Similar People
          </Typography>
          <Typography color={"text.secondary"}>
            Here’s what we’ve been up to recently.
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginLeft={2}
          >
            View all
          </Box>
        </Box>
      </Box>
      <Grid container spacing={4}>
        {similar.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              component={"a"}
              href={""}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={"flex"}
                flexDirection={"column"}
                sx={{ backgroundImage: "none" }}
              >
                <CardMedia
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    position: "relative",
                    backgroundImage: `url(https://wef-images.s3.eu-central-1.amazonaws.com/images/${item.id}.jpg)`,
                  }}
                >
                  <Box
                    component={"svg"}
                    viewBox="0 0 2880 480"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      color: theme.palette.background.paper,
                      transform: "scale(2)",
                      height: "auto",
                      width: 1,
                      transformOrigin: "top center",
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z"
                      fill="currentColor"
                    />
                  </Box>
                </CardMedia>
                <Box component={CardContent} position={"relative"}>
                  <Typography variant={"h6"} gutterBottom>
                    {item.name}
                  </Typography>
                  <Typography color="text.secondary">{item.title}</Typography>
                </Box>
                <Box flexGrow={1} />
                <Box padding={2} display={"flex"} flexDirection={"column"}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      {/* <Avatar
                        src={item.}
                        sx={{ marginRight: 1 }}
                      /> */}
                      {/* <Typography color={"text.secondary"}>
                        {item.author.name}
                      </Typography> */}
                    </Box>
                    <Typography color={"text.secondary"}>
                      {item.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SimilarStories;
